<template>
  <div>

    <b-btn
      variant="primary"
      @click="excluirVarios"
      :disabled="!selected.length > 0"
      class="float-left mb-1"
    >
      <i class="fas fa-trash"></i> Excluir Selecionados</b-btn
    >
    <b-table
      :items="lista"
      :fields="campos"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      selectable
      select-mode="multi"
      show-empty
      ref="agendasTable"
      @row-selected="onRowSelected"
    >
      <template #cell(selected)="{ rowSelected }">
        <b-form-checkbox disabled :checked="rowSelected"></b-form-checkbox>
      </template>
         <template #empty>
        <empty-state>
          <div>
            Nenhuma agenda encontrada, altere os filtros ou clique em
            <b-btn
              @click="novo"
              variant="primary"
              size="sm"
              class="mx-2"
            >
              <i class="fas fa-plus"></i> Agenda </b-btn
            >
            para cadastrar.
     
         
          </div>
        </empty-state>
      </template>
      <template #head(selected)>
        <b-btn size="sm" variant="transparent" @click="selectAllRows">
          <div v-if="!selected.length">
            <i class="fas fa-check"></i> Selecionar Todos
          </div>
          <div v-if="selected.length">
            <i class="fas fa-times" v-if="selected.length"></i> Deselecionar
            Todos
          </div>
        </b-btn>
      </template>

      <template #table-busy class="text-center">
        <b-spinner  />
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" variant="warning" @click="editar(row.item)"
          ><i class="fa fa-edit"></i> Editar</b-button
        >
        <b-button
          size="sm"
          variant="danger"
          @click="excluir(row.item)"
          :disabled="loadingSecundario"
          ><i class="fa fa-trash" v-if="!loadingSecundario">
            <b-spinner small size="sm" grow v-if="loadingSecundario" />
          </i>
          Excluir</b-button
        >
      </template>

      <template #cell(data)="row">
        {{ row.item.data | moment("DD/MM/YYYY") }}
      </template>

      <template #cell(especialidade)="row">
        {{ row.item.especialidade }}
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="lista.length"
      :per-page="paginacao.registros_por_pagina"
    ></b-pagination>

    <b-modal
      id="modal-agendas"
      size="lg"
      title="Cadastrar/Editar Agenda"
      no-fade
      content-class="modalDireito"
      no-enforce-focus
    >
      <AgendasForm
        ref="agendasFormComp"
        :agendaEdit="agendaEdit"
        @success="carregar()"
      />
      <template #modal-footer="{ cancel }">
        <div class="pt-1">
          <b-btn
            variant="info"
            v-if="!!agendaEdit.id"
            @click="openLogs"
            class="mr-3 float-left"
          >
            <i class="fas fa-history"></i>
          </b-btn>
          <b-button
            variant="primary"
            size="md"
            @click="$refs.agendasFormComp.salvarAgenda()"
            type="submit"
            :disabled="loading"
          >
            <span v-if="!loading"><i class="fa fa-save"></i> Salvar</span>
            <span v-if="loading"
              ><b-spinner small size="sm" grow /> Salvando...</span
            ></b-button
          >
          <b-button
            variant="secondary"
            size="md"
            class="ml-2"
            :disabled="loading"
            @click="cancel"
            ><i class="fa fa-times"></i> Cancelar</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AgendasLib from "@/libs/AgendasLib";
import AgendasForm from "./AgendasForm.vue";
import moment from "moment";
import EmptyState from "../common/EmptyState.vue";

export default {
  components: { AgendasForm, EmptyState },
  props: {
    filtro: String,
    filtros: Object,
  },
  data() {
    return {
      loading: false,
      loadingSecundario: false,
      lista: [],
      selected: [],
      campos: [
        { key: "selected", label: "", class: "text-center" },
        { key: "data", label: "Data", sortable: true },
        { key: "hora_inicio", label: "Hora Inicio", sortable: true },
        { key: "especialidadeNome", label: "Especialidade", sortable: true },
        { key: "EntidadeNome", label: "Médico", sortable: true },
        { key: "clinic", label: "Empresa" },
        { key: "actions", label: "" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
      agendaEdit: {},
    };
  },
  async created() {
    //await this.carregar();
  },
  methods: {
    async carregar(
      dataDe = "",
      dataAte = "",
      entidade_id = "",
      especialidade = "",
      clinica = ""
    ) {
      // this.$bvModal.hide('modal-agendas')
      this.loading = true;
      //agurada atualiza os componentes da tela
      // console.log('dataDe', dataDe, 'dataAte', dataAte);
      await this.$nextTick();

      try {
        this.lista = (
          await AgendasLib.get(
            dataDe,
            dataAte,
            entidade_id,
            especialidade,
            clinica
          )
        ).map((agenda) => {
          return { ...agenda, data: moment(agenda.data).format("YYYY-MM-DD") };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    openLogs() {
      //openLog
      this.$refs.agendasFormComp.openLogs();
    },
    async novo() {
      this.agendaEdit = {};
      this.$bvModal.show("modal-agendas");
    },
    async editar(agenda) {
      this.agendaEdit = {};
      this.agendaEdit = agenda;
      await this.$nextTick();
      this.$bvModal.show("modal-agendas");
    },
    onRowSelected(items) {
      this.selected = items.map((i) => i.id);
    },
    selectAllRows() {
      if (this.selected.length == 0) {
        this.$refs.agendasTable.selectAllRows();
      } else {
        this.$refs.agendasTable.clearSelected();
      }
    },
    excluirVarios() {
      if (this.selected.length > 0) {
        this.$swal
          .fire({
            title: "Você tem certeza? ",
            html:
              "isso irá excluir todas as agendas selecionadas! Tem certeza que deseja continuar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              let someError = false;
              for (const id of this.selected) {
                const res = await AgendasLib.del({ id });

                if (!(res.data && res.data.success)) {
                  someError = true;
                }
              }
              if (someError) {
                this.showToast(
                  "error",
                  "Alguma agenda selecionada não foi excluída com sucesso!"
                );
              } else {
                this.showToast(
                  "success",
                  "Todas agendas excluídas com sucesso!"
                );
              }
              await this.carregar();
            }
          });
      }
    },
    async excluir(agenda) {
      if (agenda) {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja excluir esta agenda?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loadingSecundario = true;
              const res = await AgendasLib.del({ id: agenda.id });

              if (res.data && res.data.success) {
                this.$swal
                  .fire({
                    title: "Agenda excluída com sucesso!",
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  })
                  .then(() => {
                    this.carregar();
                  });
              } else {
                this.$swal.fire({
                  title: "Erro ao excluir agenda!",
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
              this.loadingSecundario = false;
            }
          });
      }
    },
  },
  watch: {
    loading(){
      this.$emit('loading', this.loading)
    }
  }
};
</script>

<style></style>
