<template>
  <div>
    <!-- {{ lancamento.horarios.length }} -->

    <div class="row">
      <div class="col-12 col-sm-5">
        <div class="row justify-content-center">
          <div class="col">
            <vc-calendar
              mode="date"
              color="red"
              :attributes="attributes"
              @dayclick="onDayclick"
              class="w-75 d-block ml-auto mr-auto"
              :rows="$screens({ default: 1, md: 2 })"
              :locale="{ firstDayOfWeek: 1, masks: { weekdays: 'W' } }"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row justify-content-center">
          <div class="col-12">
            <select-medico-especialidade
              @especialidade="onChangeEspecialidade($event)"
              :especialidade_id="lancamento.especialidade"
              @medico="onChangeMedico($event)"
              @especialidadeComplete="especialiadeComplete = $event"
              :medico_id="lancamento.medico"
              multiple-medico
            ></select-medico-especialidade>
          </div>
        </div>

        <div
          class="row mt-4"
          v-if="
            especialiadeComplete.especialidade &&
            especialiadeComplete.especialidade
              .toLowerCase()
              .indexOf('oftalmo') >= 0
          "
        >
          <div class="col-12 col-sm-10">
            <b-form-group>
              <b-form-checkbox
                v-model="lancamento.faz_dilatacao"
                :unchecked-value="0"
                :value="1"
                switch
                >Médico faz dilatação?</b-form-checkbox
              >
              <b-form-group class="mt-2">
                <b-form-input
                  v-model="lancamento.tempo_dilatacao"
                  :disabled="!lancamento.faz_dilatacao"
                  type="time"
                  step="0"
                  locale="br"
                ></b-form-input>
                <span class="torm-text text-muted"> * Tempo da Dilatação</span>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="row" v-if="anamneses && anamneses.length>0">
          <div class="col-12">
            <label class="">
              <b-spinner v-if="loading" small size="sm" grow />
              Anamnese
            </label>

            <v-select
              :options="anamneses"
              v-model="lancamento.anamnese"
              :reduce="(anamnese) => anamnese.id"
              type="text"
              label="nome"
            >
              <!-- <template #search="{ attributes, events }">
                  <input
                    :class="[
                      'vs__search rounded',
                      typeof validateState('anamnese') == 'boolean' &&
                      !validateState('anamnese')
                        ? 'isInvalid'
                        : '',
                    ]"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template> -->
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Nenhum resultado para <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Não encontramos nenhuma anamnese 😕.</em
                >
              </template>
            </v-select>
            <!-- <b-form-invalid-feedback
                :force-show="
                  typeof validateState('anamnese') == 'boolean' &&
                    !validateState('anamnese')
                "
              >
                Selecione uma anamnese.
              </b-form-invalid-feedback> -->
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <select-clinicas
              consultorio
              :valueConsultorio="lancamento.consultorio"
              @consultorio="lancamento.consultorio = $event"
              multiple
              v-model="lancamento.clinicas"
            ></select-clinicas>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <label class="d-flex justify-item-start">Hora Início</label>
            <b-input-group class="mb-3">
              <b-form-input
                v-mask="'##:##'"
                type="text"
                ref="hora_inicio"
                placeholder="HH:mm"
                v-model="lancamento.hora_inicio"
              ></b-form-input>
              <b-input-group-append>
                <b-form-timepicker
                  button-only
                  right
                  locale="pt-br"
                ></b-form-timepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <label class="d-flex justify-item-start">Intervalo</label>
            <b-form-input
              type="text"
              class="form-control"
              v-model="lancamento.intervalo"
              ref="intervalo"
            />
            <b-form-invalid-feedback>
              Por favor, insira um intervalo.
            </b-form-invalid-feedback>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <label class="d-flex justify-item-start">Qtd. Espera</label>
            <b-form-input
              type="number"
              class="form-control"
              v-model="lancamento.espera_qtd"
            />
          </div>
        </div>
        <div class="row mt-4 text-nowrap">
          <div class="col-12 col-sm-6 col-md-4">
            <label class="d-flex justify-item-start">Qtd. Consulta</label>
            <b-form-input
              type="number"
              class="form-control"
              ref="consulta_qtd"
              v-model="lancamento.consulta_qtd"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <label class="d-flex justify-item-start">Qtd. Retorno</label>
            <b-form-input
              type="number"
              class="form-control"
              ref="retorno_qtd"
              v-model="lancamento.retorno_qtd"
            />
          </div>
          <div class="col-7 col-sm-12 col-md-4">
            <h4 class="d-flex justify-item-start">Total</h4>
            <b-form-input
              :value="total"
              type="text"
              class="form-control"
              readonly
            />
            <b-form-invalid-feedback>
              O total não pode ser menor que 1.
            </b-form-invalid-feedback>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <label>Observação Agenda</label>
            <b-textarea v-model="lancamento.obs" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <NotificacaoConfig v-model="lancamento.alerta_config" :title="' ao agendar'" />
          </div>
        </div>
      </div>
    </div>
    <!-- <b-btn @click="gerarHorarios"
      >gerar horarios {{ lancamento.horarios.length }}</b-btn
    > -->

    <b-modal
      id="modal-horarios-table"
      title="teste"
      size="lg"
      hide-footer
      hide-header
      no-enforce-focus
    >
      <b-btn variant="danger" @click="recriarHorarios"
        ><i class="fa fa-sync" /> Re-criar horários</b-btn
      >
      <AgendaHorariosTable
        :horariosProp="lancamento.horarios"
        v-on:fecharHorarios="$bvModal.hide('modal-horarios-table')"
        @personalizouHorarios="personalizarHorarios($event)"
      />
    </b-modal>
    <div class="border-top" style="position: absolute; width: 100%; bottom: 0">
      <div class="d-flex justify-content-end p-3">
        <b-button
          variant="primary"
          class="mr-auto"
          @click="showModalHorarios()"
          :disabled="loading"
        >
          <span v-if="!loading"
            ><i class="fa fa-clock"></i> Personalizar Horários</span
          >
          <span v-if="loading"
            ><b-spinner small size="sm" grow />Personalizar Horários...</span
          ></b-button
        >
        <b-btn
          variant="primary"
          @click="salvar"
          class="mr-3"
          :disabled="loading"
        >
          <div v-if="!loading"><i class="fa fa-save" /> Salvar</div>
          <div v-else><b-spinner small /> gerando...</div>
        </b-btn>
        <b-btn :disabled="loading"> <i class="fas fa-ban"></i> Cancelar </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMedicoEspecialidade from "../../common/SelectMedicoEspecialidade.vue";
import SelectClinicas from "../../common/SelectClinicas.vue";
import EmpresasLib from "@/libs/EmpresasLib";
import AgendasLib from "../../../libs/AgendasLib.js";
import AgendaHorariosTable from "../../Agendas/AgendaHorariosTable.vue";

import AnamneseLib from "@/libs/AnamneseLib.js";
import moment from "moment";
import NotificacaoConfig from "../../Notificacao/NotificacaoConfig.vue";
export default {
  components: {
    SelectMedicoEspecialidade,
    SelectClinicas,
    AgendaHorariosTable,
    NotificacaoConfig
},
  data() {
    return {
      lancamento: {
        medico: [],
        clinicas: [],
        especialidade: 0,
        data: [],
        consultorio: 0,
        anamnese: null,
        faz_dilatacao: 0,
        tempo_dilatacao: "00:00",
        hora_inicio: "",
        intervalo: "",
        espera_qtd: "",
        consulta_qtd: "",
        retorno_qtd: "",
        obs:'',
        total: "",
        horarios: [],
        alerta_config:{
          tipo:'Nenhum',
          usuarios:[],
          grupos:[],
        }
      },
      especialiadeComplete: {},
      loading: false,
      clinicas: [],
      anamneses: [],
      days: [],
      isRange: true,
      horariosGerados: false,
    };
  },
  watch: {
    "lancamento.consulta_qtd": function (o, n) {
      o;
      n;
      // if (n && o && n != "" && o != "" && n != o) this.qtdHorariosAlterada();
    },
    "lancamento.retorno_qtd": function (o, n) {
      o;
      n;
      // if (n && o && n != "" && o != "" && n != o) this.qtdHorariosAlterada();
    },
  },
  mounted() {
    this.carregaClinicas();
    this.carregaAnamneses();
  },
  methods: {
    async recriarHorarios() {
      await this.gerarHorarios(true);
      this.$bvModal.hide("modal-horarios-table");
      await this.$nextTick();
      this.showModalHorarios();
    },
    async qtdHorariosAlterada() {
      if (this.horariosGerados) {
        await new Promise((resolve) => {
          this.$swal({
            title: "Atenção",
            text: "Você já editou os horarios deseja re-criar todos os horarios?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then(async (r) => {
            if (r.isConfirmed) {
              this.horariosGerados = false;
              this.$nextTick();
              this.gerarHorarios();
            }
            resolve();
          });
        });
      }
    },
    onChangeMedico(medico) {
      this.lancamento.medico = medico;
    },
    personalizarHorarios(horarios) {
      // console.log("horarios recebidos", horarios);
      this.$forceUpdate();

      // this.agenda.consulta_qtd =
      //   parseInt(this.agenda.consulta_qtd) + parseInt(horarios.horariosAdded);
      this.lancamento.horarios = Object.assign([], horarios.horarios);

      const _horarios = this.lancamento.horarios.filter(
        (h) => h._rowStatus !== "deleted"
      );
      this.lancamento.retorno_qtd = _horarios.filter(
        (h) => h.retorno == 1
      ).length;

      this.lancamento.consulta_qtd = _horarios.filter((h) => !h.retorno).length;
      this.lancamento.total = this.total;
      // console.log("horarios da lancamento", this.agenda.horarios);
      this.$bvModal.hide("modal-horarios-table");
    },
    async showModalHorarios() {
      await this.qtdHorariosAlterada();
      if (!this.horariosGerados) {
        if ((await this.gerarHorarios()) === true) {
          console.debug("Vai abrir o modal de horários");
          this.$bvModal.show("modal-horarios-table");
        }
      } else {
        this.$bvModal.show("modal-horarios-table");
      }
    },
    onChangeEspecialidade(especialidade) {
      // console.log("onChangeEspecialidade", especialidade);
      this.lancamento.especialidade = especialidade;
    },
    async carregaClinicas() {
      this.clinicas = (await EmpresasLib.getEmpresasGrupo()).map((emp) => {
        return { ...emp, nome: emp.fantasia + " (" + emp.sigla + ")" };
      });
      // this.empresa = this.empresas[0];
    },
    async carregaAnamneses() {
      this.anamneses = await AnamneseLib.get();
    },

    async gerarHorarios(force = false) {
      console.debug(
        "Gerar horarios foi chamado",
        "force? ",
        force,
        "os horários já foram gerados?",
        this.horariosGerados
      );

      if (this.horariosGerados && !force) {
        return;
      }

      let {
        hora_inicio,
        intervalo,
        // total,
        consulta_qtd,
        retorno_qtd,
      } = this.lancamento;

      let errors = [];
      if (!hora_inicio) {
        errors.push("Preencha o horário de inicio da agenda!");
      }
      if (!intervalo) {
        errors.push("Preencha o intervalo da agenda!");
      }
      if (!consulta_qtd) {
        errors.push("Preencha a quantia de consultas da agenda!");
      }
      if (!retorno_qtd) {
        this.lancamento.retorno_qtd = 0;
        // errors.push("Preencha a quantia de retornos da agenda!");
      }
      if (errors.length > 0) {
        let errDesc = errors.reduce((it, vl) => {
          it += vl + " <hr />";
          return it;
        }, "");
        // console.log(errDesc);
        this.$swal({
          title: "Atenção",
          html: `<b>Não foi possível gerar os horários... Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${errDesc}</span>`,
          icon: "error",
        });
        return false;
      } else {
        this.lancamento.horarios = [];
        this.$forceUpdate();
        await this.$nextTick();
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        this.lancamento.total = this.total;

        let horarioAtual = hora_inicio.split(":");
        horarioAtual.splice(2, 1);
        horarioAtual = horarioAtual.join(":");

        //   if (
        //     this.horariosFiltrados.length !== total ||
        //     (this.horarioInicial != horarioAtual && !isNaN(this.agenda.id))
        //   )
        {
          this.lancamento.hora_inicio = horarioAtual;

          // if (this.horariosFiltrados.length > 0) {
          //   this.lancamento.horarios.forEach((_, i) => {
          //     this.$set(this.lancamento.horarios[i], "_rowStatus", "deleted");
          //   });
          // }

          for (let i = 0; i < consulta_qtd; i++) {
            const horarios = this.lancamento.horarios.filter(
              (h) => h._rowStatus !== "deleted" || !h._rowStatus
            );
            let horario =
              horarios.length > 0
                ? moment(
                    horarios[horarios.length - 1].horario.replace(":", ""),
                    "hmm"
                  )
                    .add(intervalo, "minutes")
                    .format("HH:mm")
                : hora_inicio;

            this.lancamento.horarios.push({
              horario,
              id_paciente: null,
              paciente: null,
              telefone: null,
              idade: null,
              cpf: null,
              obs: null,
              retorno: null,
              agendado: 0,
              compareceu: 0,
              procedimento: 0,
              confirmou: 0,
              questionario: {},
              id_horario_retorno: null,
              _rowStatus: "added",
            });
          }

          for (let i = 0; i < retorno_qtd; i++) {
            const horarios = this.lancamento.horarios.filter(
              (h) => h._rowStatus !== "deleted" || !h._rowStatus
            );
            // if(horarios.length > 0 && i === 0 )i = 1
            let horario =
              horarios.length > 0
                ? moment(
                    horarios[horarios.length - 1].horario.replace(":", ""),
                    "hmm"
                  )
                    .add(intervalo, "minutes")
                    .format("HH:mm")
                : hora_inicio;
            this.lancamento.horarios.push({
              horario,
              id_paciente: null,
              paciente: null,
              telefone: null,
              idade: null,
              cpf: null,
              obs: null,
              retorno: 1,
              agendado: 0,
              compareceu: 0,
              procedimento: 0,
              confirmou: 0,
              questionario: {},
              id_horario_retorno: null,
              _rowStatus: "added",
            });
          }
        }
        this.$forceUpdate();
        // console.log("Gerou os horários", this.lancamento.horarios);
        this.horariosGerados = true;
        return true;
      }
    },
    async salvar() {
      this.loading = true;
      this.lancamento.total = this.total;

      // console.log(12);
      await this.gerarHorarios();

      try {
        console.debug("Vai salvar: ", this.lancamento);
        let result = await AgendasLib.lancarMultiplos(this.lancamento);
        // console.log("result", result);
        if (result && result.success) {
          this.showToast("success", "Lançamentos foram gerados!");
          this.$emit("success");
        }else{
          this.showToast("error", result.message);

        }
      } catch (error) {
        this.showToast("error", error);
      }

      this.loading = false;
    },
    onDayclick(day) {
      const idx = this.lancamento.data.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.lancamento.data.splice(idx, 1);
      } else {
        this.lancamento.data.push({
          id: day.id,
          date: day.date,
        });
      }
    },
  },
  computed: {
    dates() {
      return this.lancamento.data.map((d) => d.date);
    },
    attributes() {
      return this.dates.map((d, i) => ({
        key: i,
        dates: d,
        highlight: true,
        order: i,
      }));
    },
    total() {
      return (
        (this.lancamento.consulta_qtd
          ? parseInt(this.lancamento.consulta_qtd)
          : 0) +
        (this.lancamento.retorno_qtd
          ? parseInt(this.lancamento.retorno_qtd)
          : 0)
      );
    },
  },
};
</script>

<style></style>
