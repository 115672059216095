<template>
  <div class="container-fluid">
    <BarraCrud :onFiltrar="filtrar" :botaoNovo="true" :onNovo="onNovo">
      <template #extraButtonLeft>
        <b-btn class="mr-3" @click="openLancamentoMultiplo">
          <i class="fas fa-calendar-alt"></i> Lançamento Multiplo</b-btn
        >
      </template>
    </BarraCrud>

    <b-modal
      id="modalLancamentoMultiplo"
      no-fade
      hide-footer
      content-class="modalDireito"
    >
      <template #modal-title>
        <i class="fas fa-calendar-alt"></i> Lançamento Multiplo
      </template>
      <agenda-lancamento-form @success="onLancarMultiplos" />
    </b-modal>

    <div class="row align-items-center">
      <div class="col-12 col-sm-2 p-0 m-n4">
        <h4><i class="fas fa-filter"></i> Filtros</h4>
      </div>

      <div class="col-12 col-sm-2 m-0  ">
        <b-form-group>
          <label><i class="far fa-calendar-minus"></i> Data</label>

          <date-picker
            style="max-width: 100%; width: 100%"
            v-model="filtros.data.valor"
            valueType="format"
            lang="br"
            @change="carregarFiltro()"
            range
            :disabled="loading"
          ></date-picker>
        </b-form-group>
      </div>

      <div class="col-12 col-sm-3">
        <b-form-group>
          <label><i class="fas fa-user-nurse"></i> Médico</label>
          <b-spinner
            v-if="loadingEntidades"
            class="ml-3 align-baseline"
            small
            size="sm"
            grow
          />

          <!-- <select-entidade label=""  @loading="loadingEntidades = $event"     @change="carregarFiltro()" size="sm"  v-model="filtros.Entidade.valor" :tipo="'medico'"></select-entidade>
       -->
          <select-paciente
            :tipo="['medico']"
            :showBotaoAdd="false"
            :showBotaoEdit="false"
            setNullOnEmpty
            :disabled="loading"
            :selecionado="filtros.Entidade.valor"
            @paciente="onMedico"
          ></select-paciente>
          <!-- <v-select
          
            v-model="filtros.Entidade.valor"
            class="style-chooser"
          
            :options="EntidadesOptions"
              :reduce="(etd) => etd.code"
            @change="carregarFiltro()"
          >
           <template #option="item">
                  <small class="font-sm" >{{
                    item.label
                  }}</small>
                </template>
          </v-select> -->
          <!--         
          <b-form-select
            @change="carregarFiltro()"
            v-model="filtros.Entidade.valor"
            size="sm"
            :options="EntidadesOptions"
            :disabled="loadingEspecialidades"
          ></b-form-select> -->
        </b-form-group>
      </div>
      <div class="col-12 col-sm-3">
        <b-form-group>
          <label><i class="fas fa-book-medical"></i> Especialidades</label>
          <b-spinner
            v-if="loadingEspecialidades"
            class="align-baseline"
            small
            size="sm"
            grow
          />

          <!-- <b-form-select
            @change="carregarFiltro()"
            v-model="filtros.especialidade.valor"
            size="sm"
            :options="especialidadesOptions"
            :disabled="loadingEspecialidades"
          ></b-form-select> -->

          <v-select
            :disabled="loading"
            aria-autocomplete=""
            v-model="filtros.especialidade.valor"
            class="style-chooser"
            :reduce="(especialidadesOptions) => especialidadesOptions.code"
            :options="especialidadesOptions"
            @change="carregarFiltro()"
          >
          </v-select>
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2 pr-0">
        <b-form-group>
          <!-- <label><i class="fas fa-clinic-medical"></i> Clinica</label> -->
          <b-spinner
            v-if="loadingClinicas"
            class="align-baseline"
            small
            size="sm"
            grow
          />

          <!-- <b-form-select
            @change="carregarFiltro()"
            v-model="filtros.especialidade.valor"
            size="sm"
            :options="especialidadesOptions"
            :disabled="loadingEspecialidades"
          ></b-form-select> -->

          <select-clinicas
            @input="carregarFiltro"
            :disabled="loading"
            v-model="filtros.clinica.valor"
          ></select-clinicas>
          <!-- <v-select
            aria-autocomplete=""
            v-model="filtros.clinica.valor"
            class="style-chooser"
            :reduce="(clinic) => clinic.id"
            :options="clinicas"
            label="nome"
            @change="carregarFiltro()"
          >
            <template #search="{ attributes, events }">
              <input
                :class="'vs__search rounded'"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Nenhum resultado para <em>{{ search }}</em
                >.
              </template>
              <em style="opacity: 0.5" v-else
                >Não encontramos nenhuma clínica 😕.</em
              >
            </template>
          </v-select> -->
        </b-form-group>
      </div>

      <!-- <b-form-group>
              <b-button variant="primary" size="sm"> Filtrar</b-button>
            </b-form-group> -->
    </div>

    <AgendasTable
      @loading="loading = $event"
      :filtros="filtros"
      :filtro="filtro"
      ref="agendaTableComp"
    />
  </div>
</template>

<script>
// import AgendasForm from '../../components/Agendas/AgendasForm'
import BarraCrud from "@/components/common/BarraCrud.vue";
import AgendasTable from "../../components/Agendas/AgendasTable.vue";
import EspecialidadesLib from "../../libs/EspecialidadesLib";
import EntidadesLib from "../../libs/EntidadesLib";
import EmpresasLib from "../../libs/EmpresasLib";
import AgendaLancamentoForm from "@/components/Agendas/LancamentoMultiplo/AgendaLancamentoForm";
import SelectClinicas from "../../components/common/SelectClinicas.vue";
import SelectPaciente from "../../components/common/SelectPaciente.vue";
//import SelectEntidade from "../../components/common/SelectEntidade.vue";
import moment from "moment";

export default {
  data() {
    return {
      filtros: {
        data: {
          ativo: true,
          valor: [],
        },
        Entidade: {
          ativo: false,
          valor: "",
        },
        especialidade: {
          ativo: false,
          valor: "",
        },
        clinica: {
          ativo: false,
          valor: "",
        },
      },
      loading: false,
      filterOn: [],
      EntidadesOptions: [],
      especialidadesOptions: [],
      clinicas: [],
      loadingEntidades: false,
      loadingEspecialidades: false,
      loadingClinicas: false,
    };
  },
  components: {
    // AgendasForm,
    AgendasTable,
    BarraCrud,
    AgendaLancamentoForm,
    SelectClinicas,
    //SelectEntidade: ()=>import("../../components/common/SelectEntidade.vue"),
    SelectPaciente,
  },
  props: {
    filtro: Array,
  },
  methods: {
    async carregarFiltro() {
      //console.log('chamou carregarFiltro', this.filtros);
      if (this.loading) return;

      await this.$refs.agendaTableComp.carregar(
        this.filtros.data.valor[0],
        this.filtros.data.valor[1],
        this.filtros.Entidade.valor,
        this.filtros.especialidade.valor,
        this.filtros.clinica.valor
      );
    },
    async onLancarMultiplos() {
      this.$bvModal.hide("modalLancamentoMultiplo");
      await this.$refs.agendaTableComp.carregar();
    },
    onMedico(medico) {
      console.log("onMedico", medico);
      if (!medico || !medico.id) {
        this.filtros.Entidade.valor = null;
      } else {
        this.filtros.Entidade.valor = medico.id;
      }

      this.carregarFiltro();
    },
    async carregaEntidades() {
      await this.$nextTick();
      this.$forceUpdate();
      this.loadingEntidades = true;
      const Entidades = (await EntidadesLib.get("medico")).entidades;
      // console.log("aaaaaa", Entidades);

      this.EntidadesOptions = Entidades.map((med) => {
        return { label: med.razao_social, code: med.id };
      });
      this.loadingEntidades = false;
    },
    async carregaClinicas() {
      this.loadingClinicas = true;
      this.clinicas = (await EmpresasLib.getEmpresasGrupo()).map((emp) => {
        return { ...emp, nome: emp.fantasia + " (" + emp.sigla + ")" };
      });
      this.loadingClinicas = false;
      // this.empresa = this.empresas[0];
    },
    async carregaEspecialidades() {
      this.loadingEspecialidades = true;
      const especialidade = await EspecialidadesLib.get();

      this.especialidadesOptions = especialidade.map((espec) => {
        return { label: espec.especialidade, code: espec.id };
      });
      this.loadingEspecialidades = false;
    },

    filtrar(filtro) {
      this.filtro = filtro;
    },
    onNovo() {
      this.$refs.agendaTableComp.novo();
    },
    openLancamentoMultiplo() {
      this.$nextTick(function() {
        this.$bvModal.show("modalLancamentoMultiplo");
      });
      this.$forceUpdate();
    },
  },
  async mounted() {
    this.loading = true;
    this.$forceUpdate();
    this.carregaEspecialidades();
    this.loading = false;
    this.filtros.data.valor = [
      moment()
        .subtract(1, "week")
        .format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
  },
  watch: {
    // "filtros":{
    //   deep: true,
    //   async handler(){
    //     await this.carregarFiltro()
    //   }
    // },
    // "filtros.Entidade.valor"() {
    //   this.carregarFiltro();
    // },
    // "filtros.data.valor"() {
    //   // console.log('chamou carregarFiltro pela data');
    //   this.carregarFiltro();
    // },
    // "filtros.especialidade.valor"() {
    //   this.carregarFiltro();
    // },
    // "filtros.clinica.valor"() {
    //   this.carregarFiltro();
    // },
  },
};
</script>

<style lang="scss">
/*
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #f1f1f1;
  border: default;
 
  color: #394066;
}*/

// .style-chooser .vs__clear,
// .style-chooser .vs__open-indicator {

// }
</style>
